import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
} from '@angular/forms';
import { map, Observable, pairwise, tap } from 'rxjs';
import { InterpolatingTextFormControlComponent } from '../interpolating-text-form-control/interpolating-text-form-control.component';
import { InsertMentionOperation } from '@activepieces/ui/common';
import { ArrayProperty } from '@activepieces/pieces-framework';
import { createConfigsFormControls } from '../shared';
import { isEmpty, get, set } from 'lodash';

@Component({
  selector: 'app-array-form-control',
  templateUrl: './array-form-control.component.html',
  styleUrls: ['./array-form-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ArrayFormControlComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ArrayFormControlComponent,
    },
  ],
})
export class ArrayFormControlComponent implements ControlValueAccessor {
  formArray: FormArray<FormControl<string> | UntypedFormGroup>;
  @Input() pieceName: string;
  @Input({ required: true }) formFieldsTemplate: TemplateRef<unknown>;
  @Input({ required: true }) property: ArrayProperty<true>;
  @Input({ required: true }) prefix: string;
  @Input() dynamicInputTemplate: TemplateRef<unknown>;
  @ViewChild('textControl') firstInput: InterpolatingTextFormControlComponent;
  removeItemTooltip = $localize`Remove item`;
  updateValueOnChange$: Observable<void> = new Observable<void>();

  createForm(propertiesValues: Record<string, unknown> | string) {
    const properties = this.property.properties;
    if (
      typeof propertiesValues !== 'string' &&
      properties &&
      Object.keys(properties).length > 0
    ) {
      const controls = createConfigsFormControls(
        properties,
        propertiesValues,
        this.fb
      );

      this.formArray.push(
        this.fb.group({
          ...controls,
        })
      );
    } else if (typeof propertiesValues === 'string') {
      this.formArray.push(
        new FormControl<string>(propertiesValues, { nonNullable: true })
      );
    }
  }

  onChange: (val: unknown) => void = () => {
    //ignore
  };
  onTouched: () => void = () => {
    //ignore
  };

  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef) {
    this.formArray = this.fb.array([]) as FormArray<
      FormControl<string> | UntypedFormGroup
    >;
    this.updateValueOnChange$ = this.formArray.valueChanges.pipe(
      tap((value) => {
        this.onChange(value);
      }),
      map(() => void 0)
    );
  }
  /** type of value is string only when you swtich to customized inputs that happens because of change detection running before the form control is removed from template*/
  writeValue(pvalue: Array<string | Record<string, unknown>> | null): void {
    const values: Array<string | Record<string, unknown>> = pvalue
      ? JSON.parse(JSON.stringify(pvalue))
      : [];

    if (typeof pvalue !== 'string') {
      if (this.property.required && values.length === 0) {
        if (this.property.properties) {
          values.push({});
        } else {
          values.push('');
        }
      }
      if (this.isOdinAction()) {
        this.formArray.valueChanges
          .pipe(pairwise())
          // eslint-disable-next-line rxjs-angular/prefer-async-pipe
          .subscribe(([previousValues, currentValues]) => {
            previousValues.forEach((prevValue, index) => {
              // console.log('previousValue', prevValue);
              // console.log('currentValue', currentValues[index]);
              const currentValue: any = get(currentValues, `[${index}]`, {});
              // JSON.stringify(prevValue) !== JSON.stringify(currentValue)
              if (
                !isEmpty(get(currentValue, 'type', '')) &&
                (get(prevValue, 'showDropdownValue', false) !=
                  get(currentValue, 'showDropdownValue', false) ||
                  get(currentValue, 'type', '') != 'dropdown')
              ) {
                if (
                  get(currentValue, 'showDropdownValue', '') &&
                  get(currentValue, 'dropdownLabel', []).length > 0
                ) {
                  console.log('clear label');
                  const dropdownValue: any = [];
                  for (const item of get(currentValue, 'dropdownLabel', [])) {
                    dropdownValue.push({
                      label: item.label,
                      value: item.label,
                    });
                  }
                  set(currentValue, 'dropdownValue', dropdownValue);
                  set(currentValue, 'dropdownLabel', []);
                  this.formArray.at(index).setValue(currentValue);
                } else if (
                  !get(currentValue, 'showDropdownValue', false) &&
                  get(currentValue, 'dropdownValue', []).length > 0
                ) {
                  console.log('clear value');
                  const dropdownLabel: any = [];
                  for (const item of get(currentValue, 'dropdownValue', [])) {
                    dropdownLabel.push({ label: item.label });
                  }
                  set(currentValue, 'dropdownLabel', dropdownLabel);
                  set(currentValue, 'dropdownValue', []);
                  this.formArray.at(index).setValue(currentValue);
                }
                if (
                  get(currentValue, 'type', '') != 'dropdown' &&
                  (get(currentValue, 'dropdownValue', []).length > 0 ||
                    get(currentValue, 'dropdownLabel', []).length > 0)
                ) {
                  console.log('clear dropdown');
                  set(currentValue, 'dropdownLabel', []);
                  set(currentValue, 'dropdownValue', []);
                  this.formArray.at(index).setValue(currentValue);
                }
                // console.log(`Change detected at index ${index}`);
                // console.log('Previous value:', prevValue);
                // console.log('Current value:', currentValue);
              }
            });
          });
      }
      this.formArray.clear();
      values.forEach((v) => {
        this.createForm(v);
      });
      this.formArray.markAllAsTouched();
      this.cd.markForCheck();
    }
  }

  registerOnChange(fn: (val: unknown) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.formArray.disable();
    } else {
      this.formArray.enable();
    }
  }
  addValue() {
    if (this.isAnArrayOfObjects()) {
      this.createForm({});
      this.formArray.markAllAsTouched();
      this.cd.markForCheck();
    } else {
      this.createForm('');
    }
  }

  removeValue(index: number) {
    if (this.itemsCanBeDeleted()) {
      this.formArray.removeAt(index);
    }
  }

  async addMention(
    textControl: InterpolatingTextFormControlComponent,
    mention: InsertMentionOperation
  ) {
    await textControl.addMention(mention);
  }
  focusFirstInput() {
    this.firstInput.focusEditor();
  }

  getFormControlAtIndex(index: number): FormControl {
    const control = this.formArray.controls[index];
    return control as any;
  }
  getFormControlGroupAtIndex(c: any): FormControl {
    return c as any;
  }
  validate() {
    if (this.formArray.invalid) {
      return { invalid: true };
    }
    return null;
  }

  isAnArrayOfObjects(): boolean {
    return (
      this.property && Object.keys(this.property.properties || {}).length > 0
    );
  }

  itemsCanBeDeleted(): boolean {
    const isEnabled = this.formArray.enabled;
    const isRequiredAndHasMoreThanOneItem =
      this.property.required && this.formArray.controls.length > 1;
    const isNotRequired = !this.property.required;
    return isEnabled && (isRequiredAndHasMoreThanOneItem || isNotRequired);
  }
  isOdinAction(): boolean {
    return /odin-action$/gi.test(this.pieceName);
  }
}

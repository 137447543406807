import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  AuthenticationService,
  FlagService,
  RedirectService,
  OdinService,
  fadeInUp400ms,
} from '@activepieces/ui/common';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { StatusCodes } from 'http-status-codes';
import { ApEdition, ApFlagId, ErrorCode } from '@activepieces/shared';
import { OtpType } from '@activepieces/ee-shared';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { get, isEmpty, every, omit } from 'lodash';
import { Router } from '@angular/router';

interface SignInForm {
  email: FormControl<string>;
  password: FormControl<string>;
}
@Component({
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  animations: [fadeInUp400ms],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent implements OnInit, OnDestroy {
  loginForm: FormGroup<SignInForm>;
  showInvalidEmailOrPasswordMessage = false;
  errorMessage = 'Invalid email or password';
  loading = false;
  authenticate$: Observable<void> | undefined;
  isCommunityEdition$: Observable<boolean>;
  showResendVerification = false;
  sendingVerificationEmail = false;
  showDisabledUser = false;
  domainIsNotAllowed = false;
  invitationOnlySignIn = false;
  loginsWithEmailEnabled$: Observable<boolean>;
  showSignUpLink$: Observable<boolean>;
  sendVerificationEmail$?: Observable<void>;
  noProjectsFound = false;
  renderIframe = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public afAuth: AngularFireAuth,
    public authenticationService: AuthenticationService,
    public http: HttpClient,
    private flagsService: FlagService,
    private redirectService: RedirectService,
    private snackbar: MatSnackBar
  ) {
    this.renderIframe = window.self !== window.top;
    this.loginsWithEmailEnabled$ = of(true);

    this.showSignUpLink$ = this.flagsService.isFlagEnabled(
      ApFlagId.SHOW_SIGN_UP_LINK
    );
    this.isCommunityEdition$ = this.flagsService
      .getEdition()
      .pipe(map((ed) => ed === ApEdition.COMMUNITY));
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.email],
      }),
      password: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  ngOnInit(): void {
    const redirectUrl = this.redirectService.redirectRoute || '';
    const urlTree = this.router.parseUrl(decodeURIComponent(redirectUrl));
    const queryParams = urlTree.queryParams;
    const token = queryParams['token'] || '';
    const email = new URLSearchParams(redirectUrl).get('email') || '';
    const uid = new URLSearchParams(redirectUrl).get('uid') || '';
    const projectId = new URLSearchParams(redirectUrl).get('projectId') || '';
    const isRedirect: boolean = every(
      [token, email, uid],
      (item: string): boolean => !isEmpty(item)
    );
    if (this.authenticationService.isLoggedIn() && !isRedirect) {
      this.router.navigate(['/flows']).then(() => {
        this.loading = false;
      });
      return;
    } else if (isRedirect) {
      this.loading = true;
      this.authenticationService
        .logout()
        .then(() => {
          return OdinService.signInWithToken(
            this.http,
            token,
            email,
            uid,
            projectId
          );
        })
        .then((response) => {
          console.log(`response, ${JSON.stringify(response)}`);
          this.authenticationService.saveUser(
            omit(response.body, 'token', 'password') as any,
            get(response.body, 'token', '')
          );
          this.redirectToURL(redirectUrl);
        })
        .catch((err) => {
          console.error(`[sign-in.component#constructor] error: ${err}`);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  signInWithGoogle() {
    this.authenticate$ = OdinService.signInWithGoogle(
      this.http,
      this.afAuth
    ).pipe(
      catchError((error) => {
        alert(error);
        this.showInvalidEmailOrPasswordMessage = true;
        this.errorMessage = get(
          error,
          'error.message',
          'Invalid email/password'
        );
        this.loading = false;
        return of(null);
      }),
      tap((response: any) => {
        console.log('response: ', response);
        if (response) {
          response = get(response, 'body', {});
          if (!isEmpty(response)) {
            this.authenticationService.saveUser(
              omit(response, 'token', 'password') as any,
              get(response, 'token', '')
            );
            this.redirect();
          }
        }
      }),
      map(() => void 0)
    );
  }

  signIn(): void {
    if (this.loginForm.valid && !this.loading) {
      this.loading = true;
      this.showInvalidEmailOrPasswordMessage = false;
      this.showResendVerification = false;
      this.invitationOnlySignIn = false;
      this.noProjectsFound = false;
      this.showDisabledUser = false;
      this.domainIsNotAllowed = false;
      const request = this.loginForm.getRawValue();
      this.authenticate$ = OdinService.signInWithOdin(
        request,
        this.http,
        this.afAuth
      ).pipe(
        catchError((error: HttpErrorResponse) => {
          this.showInvalidEmailOrPasswordMessage =
            error.status === StatusCodes.UNAUTHORIZED ||
            error.status === StatusCodes.BAD_REQUEST;
          if (error.status === StatusCodes.FORBIDDEN) {
            this.showResendVerification =
              error.error.code === ErrorCode.EMAIL_IS_NOT_VERIFIED;
            this.showDisabledUser =
              error.error.code === ErrorCode.USER_IS_INACTIVE;
            this.domainIsNotAllowed =
              error.error.code === ErrorCode.DOMAIN_NOT_ALLOWED;
            this.invitationOnlySignIn =
              error.error.code === ErrorCode.INVITATION_ONLY_SIGN_UP;
          }
          if (
            error.status == 500 &&
            error.error.message.includes('at least one project')
          ) {
            this.noProjectsFound = true;
          }

          this.loading = false;
          return of(null);
        }),
        tap((response: any) => {
          if (response) {
            this.authenticationService.saveUser(
              omit(response.body, 'token', 'password') as any,
              get(response.body, 'token', '')
            );
            this.redirect();
          }
        }),
        map(() => void 0)
      );
    }
  }

  redirect() {
    this.loading = false;
    this.redirectService.redirect();
  }

  sendVerificationEmail() {
    this.sendingVerificationEmail = true;
    this.sendVerificationEmail$ = this.authenticationService
      .sendOtpEmail({
        email: this.loginForm.getRawValue().email,
        type: OtpType.EMAIL_VERIFICATION,
      })
      .pipe(
        tap(() => {
          this.snackbar.open('Verfication email sent, please check your inbox');
          this.sendingVerificationEmail = false;
          this.showResendVerification = false;
        })
      );
  }
  redirectToURL(redirectUrl: string) {
    this.loading = false;
    if (redirectUrl) {
      this.router.navigateByUrl(decodeURIComponent(redirectUrl));
    } else {
      this.router.navigate(['/flows']);
    }
  }
  ngOnDestroy(): void {
    this.loading = false;
  }
}

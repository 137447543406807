<div class="ap-text-body ap-h-full">
  <router-outlet></router-outlet>
</div>

<div *ngIf="(loading$ | async) || (loadingTheme$ | async) || isLoading"
  class="ap-flex ap-flex-grow ap-justify-center ap-items-center ap-h-full route-loader ap-w-full">
  <!-- <svg-icon [svgStyle]="{ width: '50px', height: '50px' }" src="assets/img/custom/loading.svg"
    class="loading-spin-animation grey-spinner" [applyClass]="true"></svg-icon> -->
    <ng-lottie [options]="options" width="100px" class="ap-text-center"></ng-lottie>
</div>
<ng-container *ngIf="loggedInUser$ | async"></ng-container>
<ng-container *ngIf="routeLoader$ | async"></ng-container>
<ng-container *ngIf="importTemplate$ | async"></ng-container>
<ng-container *ngIf="theme$ | async"> </ng-container> 
<ng-container *ngIf="setTitle$ | async"></ng-container>
<ng-container *ngIf="embeddedRouteListener$ | async"></ng-container>
<ng-container *ngIf="redirect$ | async"> </ng-container>
<ng-container *ngIf="toggleLoading$ | async"></ng-container>
<ap-iframe-listener></ap-iframe-listener>
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  BehaviorSubject,
  lastValueFrom,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { Store } from '@ngrx/store';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { get, isEmpty } from 'lodash';

import {
  CommonActions,
  ProjectService,
  FlagService,
  AppearanceService,
  environment,
  PlatformService,
  FlowBuilderService,
  TelemetryService,
  OdinService,
} from '@activepieces/ui/common';
import { compareVersions } from 'compare-versions';
import { ApEdition, ApFlagId, LocalesEnum } from '@activepieces/shared';
import {
  EmbeddingService,
  AuthenticationService,
  fadeInUp400ms,
  LocalesService,
} from '@activepieces/ui/common';
import { MatDialog } from '@angular/material/dialog';
import { Platform } from '@activepieces/shared';
import { UserWithoutPassword } from '@activepieces/shared';

interface UpgradeNotificationMetaDataInLocalStorage {
  latestVersion: string;
  ignoreNotification: boolean;
}
const upgradeNotificationMetadataKeyInLocalStorage =
  'upgardeNotificationMetadata';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInUp400ms],
})
export class AppComponent implements OnInit, OnDestroy {
  routeLoader$: Observable<unknown>;
  loggedInUser$: Observable<UserWithoutPassword | undefined>;
  showUpgradeNotification$: Observable<boolean>;
  hideUpgradeNotification = false;
  logoutOldTokens$: Observable<void>;
  loading$: Subject<boolean> = new Subject();
  importTemplate$: Observable<void>;
  loadingTheme$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  theme$: Observable<void>;
  setTitle$: Observable<void>;
  isCommunityEdition$: Observable<boolean>;
  embeddedRouteListener$: Observable<boolean>;
  redirect$?: Observable<Platform | undefined>;
  isLoading = false;
  options = {
    path: OdinService.shouldWhiteLabel()
      ? '/assets/lottie/aa-loader.json'
      : '/assets/lottie/odin-loader.json',
  };

  toggleLoading$: Observable<boolean>;
  constructor(
    public dialog: MatDialog,
    private store: Store,
    private apperanceService: AppearanceService,
    private authenticationService: AuthenticationService,
    private flagService: FlagService,
    private router: Router,
    private maticonRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private embeddedService: EmbeddingService,
    private localesService: LocalesService,
    private platformService: PlatformService,
    private builderService: FlowBuilderService,
    private telemetryService: TelemetryService,
    private projectService: ProjectService
  ) {
    this.handleMessage = this.handleMessage.bind(this);
    window.addEventListener('message', this.handleMessage);
    this.toggleLoading$ = this.builderService.loading$.pipe(
      tap((res) => {
        console.log('loading triggered');
        this.loading$.next(res);
      })
    );
    this.registerMaterialIcons();
    this.theme$ = this.apperanceService.setTheme().pipe(
      tap(() => this.loadingTheme$.next(false)),
      map(() => void 0)
    );
    this.embeddedRouteListener$ = this.createEmbeddingRoutesListener();
    this.routeLoader$ = this.createRouteListenerToToggleLoadingAndSetTitle();
    this.showUpgradeNotification$ =
      this.createListenerToToggleUpgradeNotification();
    this.rediectToCorrectLocale();
    // eslint-disable-next-line rxjs-angular/prefer-async-pipe
    this.authenticationService.isLoading.subscribe((res: boolean) => {
      console.log('Loading Value', res);
      this.isLoading = res;
    });
  }

  private registerMaterialIcons() {
    this.maticonRegistry.addSvgIcon(
      'search',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/custom/search.svg'
      )
    );
    this.maticonRegistry.addSvgIcon(
      'custom_expand_less',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/custom/expand_less.svg'
      )
    );
    this.maticonRegistry.addSvgIcon(
      'custom_expand_more',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/custom/expand_more.svg'
      )
    );
  }

  ngOnInit(): void {
    this.loggedInUser$ = this.authenticationService.currentUserSubject.pipe(
      tap((user) => {
        const decodedToken = this.authenticationService.getDecodedToken();
        if (
          user == undefined ||
          Object.keys(user).length == 0 ||
          !decodedToken
        ) {
          console.log(`[app.component#ngOnInit] if user == undefined`);
          this.store.dispatch(CommonActions.clearState());
          return;
        }
        const projectId =
          new URLSearchParams(window.location.search).get('projectId') || '';
        if (
          !isEmpty(decodedToken['projectId']) &&
          decodedToken['projectId'] != projectId &&
          !isEmpty(projectId)
        ) {
          console.log(`[app.component#ngOnInit] if !isEmpty`);
          this.changeProject(user, projectId);
        } else {
          console.log(`[app.component#ngOnInit] else`);
          const token =
            new URLSearchParams(window.location.search).get('token') || '';
          if (token) {
            localStorage.setItem('access-token', token);
          }
          this.store.dispatch(
            CommonActions.loadProjects({
              user,
              currentProjectId: decodedToken['projectId'],
            })
          );
        }
        this.telemetryService.init(user);
      }),
      map(() => void 0)
    );
    this.authenticationService.setLoadingValue(false);
  }
  getUpgradeNotificationMetadataInLocalStorage() {
    try {
      const localStorageValue = localStorage.getItem(
        upgradeNotificationMetadataKeyInLocalStorage
      );
      if (localStorageValue) {
        return JSON.parse(
          localStorageValue
        ) as UpgradeNotificationMetaDataInLocalStorage;
      }
      return null;
    } catch (e) {
      return null;
    }
  }
  ignoreUpgradeNotification() {
    const metadataInLocatStorage =
      this.getUpgradeNotificationMetadataInLocalStorage()!;
    metadataInLocatStorage.ignoreNotification = true;
    localStorage.setItem(
      upgradeNotificationMetadataKeyInLocalStorage,
      JSON.stringify(metadataInLocatStorage)
    );
    this.showUpgradeNotification$ = of(false);
  }
  openUpgradeDocs() {
    window.open(
      'https://www.activepieces.com/docs/install/docker#upgrading',
      '_blank',
      'noopener noreferrer'
    );
  }

  private createRouteListenerToToggleLoadingAndSetTitle() {
    return this.router.events.pipe(
      tap((event) => {
        if (
          event instanceof NavigationStart &&
          event.url.startsWith('/flows/')
        ) {
          this.loading$.next(true);
        }
        if (event instanceof NavigationEnd) {
          let route = this.router.routerState.root;

          while (route.firstChild) {
            route = route.firstChild;
          }
          const { title } = route.snapshot.data;
          if (title) {
            this.setTitle$ = this.apperanceService.setTitle(title);
          }
          this.loading$.next(false);
        }

        if (event instanceof NavigationCancel) {
          this.loading$.next(false);
        }
        if (event instanceof NavigationError) {
          this.loading$.next(false);
        }
      })
    );
  }

  private createListenerToToggleUpgradeNotification() {
    return this.flagService.getAllFlags().pipe(
      map((res) => {
        if (res[ApFlagId.EDITION] !== ApEdition.COMMUNITY) {
          return false;
        }
        const currentVersion =
          (res[ApFlagId.CURRENT_VERSION] as string) || '0.0.0';
        const latestVersion =
          (res[ApFlagId.LATEST_VERSION] as string) || '0.0.0';
        const upgradeNotificationMetadataInLocalStorage =
          this.getUpgradeNotificationMetadataInLocalStorage();
        if (!upgradeNotificationMetadataInLocalStorage) {
          localStorage.setItem(
            upgradeNotificationMetadataKeyInLocalStorage,
            JSON.stringify({
              latestVersion: latestVersion,
              ignoreNotification: false,
            })
          );
          return compareVersions(latestVersion, currentVersion) === 1;
        } else {
          localStorage.setItem(
            upgradeNotificationMetadataKeyInLocalStorage,
            JSON.stringify({
              latestVersion: latestVersion,
              ignoreNotification:
                upgradeNotificationMetadataInLocalStorage.ignoreNotification,
            })
          );
          return (
            (!upgradeNotificationMetadataInLocalStorage.ignoreNotification &&
              compareVersions(latestVersion, currentVersion) === 1) ||
            (compareVersions(
              latestVersion,
              upgradeNotificationMetadataInLocalStorage.latestVersion
            ) === 1 &&
              compareVersions(latestVersion, currentVersion) === 1)
          );
        }
      })
    );
  }

  private createEmbeddingRoutesListener() {
    return this.router.events.pipe(
      switchMap((routingEvent) => {
        return this.embeddedService.getIsInEmbedding$().pipe(
          tap((embedded) => {
            if (
              routingEvent instanceof NavigationStart &&
              routingEvent.url.startsWith('/embed') &&
              embedded
            ) {
              console.error('visiting /embed after init');
              this.router.navigate(['/'], { skipLocationChange: true });
            }
            if (embedded && routingEvent instanceof NavigationEnd) {
              this.embeddedService.activepiecesRouteChanged(this.router.url);
            }
          })
        );
      })
    );
  }
  private rediectToCorrectLocale() {
    if (environment.production) {
      //TODO: once we start having /en routes this logic should be altered to checking (if the localeFromBrowserUrl is undefined, switch to what is in localstorage)
      this.redirect$ = this.authenticationService.currentUserSubject.pipe(
        switchMap((usr) => {
          const platformId = this.authenticationService.getPlatformId();
          if (usr && platformId && Object.keys(usr).length > 0) {
            return this.platformService.getPlatform(platformId).pipe(
              tap((platform) => {
                this.redirectToUserLocale(platform.defaultLocale);
              })
            );
          }
          return of(undefined).pipe(
            tap(() => {
              return this.redirectToUserLocale();
            })
          );
        })
      );
    }
  }

  /**Redirects to user locale if there's a mismatch between locale stored in localStorage and locale specified in url */
  private redirectToUserLocale(platformDefaultLocale?: LocalesEnum) {
    const currentLocaleFromUrl =
      this.localesService.getCurrentLocaleFromBrowserUrlOrDefault();
    const currentLocaleFormLocalstorageOrDefault =
      this.localesService.getCurrentLocaleFromLocalStorage() ||
      platformDefaultLocale ||
      this.localesService.defaultLocale;
    if (currentLocaleFormLocalstorageOrDefault !== currentLocaleFromUrl) {
      this.localesService.setCurrentLocale(
        currentLocaleFormLocalstorageOrDefault
      );
      this.localesService.redirectToLocale(
        currentLocaleFormLocalstorageOrDefault
      );
    }
  }
  async changeProject(user: any, projectId: string) {
    await lastValueFrom(
      this.projectService.setToken({
        projectId: projectId,
      })
    ).then(async (result: any) => {
      if (result) {
        this.authenticationService.saveToken(result.body.token);
        this.store.dispatch(
          CommonActions.loadProjects({
            user: user,
            currentProjectId: projectId,
          })
        );
      }
    });
  }
  async handleMessage(event: MessageEvent): Promise<void> {
    const origin: string = get(event, 'origin', '');

    if (this.verifyOrigin(origin)) {
      const tab = get(event, 'data.tab', '');
      const projectId = get(event, 'data.projectId', '');

      console.log(`[app.component#handleMessage] tab: ${tab}`);
      console.log(`[app.component#handleMessage] projectId: ${projectId}`);

      if (!isEmpty(tab) && !isEmpty(projectId)) {
        const currentTab = this.router.url;
        const decodedToken = this.authenticationService.getDecodedToken();
        const currentProjectId = get(decodedToken, 'projectId', '');
        console.log(`[app.component#handleMessage] currentTab: ${currentTab}`);
        console.log(
          `[app.component#handleMessage] current project id: ${currentProjectId}`
        );
        if (!isEmpty(projectId) && currentProjectId != projectId) {
          console.log(`[app.component#handleMessage] setting new projectId...`);
          const result: any = await lastValueFrom(
            this.projectService.setToken({
              projectId: projectId,
            })
          );
          console.log(
            `[app.component#handleMessage] setToken result: ${JSON.stringify(
              result.body,
              null,
              2
            )}`
          );
          if (result) {
            this.authenticationService.saveToken(result.body.token);
            /*
            this.store.dispatch(
              CommonActions.loadProjects({
                user: result.body as any,
                currentProjectId: projectId,
              })
            );
            */
            const sliced = currentTab.slice(1);
            console.log(
              `[app.component#handleMessage] currentTab.slice(1): ${sliced}`
            );
            /*
            if (sliced == tab) {
              console.log(`[app.component#handleMessage] inside if`);
              const currentUrl = this.router.url;
              this.router
                .navigate(['/'], { skipLocationChange: true })
                .then(() => {
                  this.router.navigate([currentUrl]);
                });
            } else {
              console.log(`[app.component#handleMessage] inside else`);
              this.router.navigate([`/${tab}`]);
            }
              */
            if (!isEmpty(currentTab)) {
              console.log(`[app.component#handleMessage] currentTab not empty`);
              const path: string = (currentTab as string).split('?')[0];
              const params: string = (currentTab as string).split('?')[1];
              console.log(`[app.component#handleMessage] path: ${path}`);
              console.log(`[app.component#handleMessage] params: ${params}`);
              const searchParams: URLSearchParams = new URLSearchParams(params);
              searchParams.set('projectId', projectId);
              const newParams: string = searchParams.toString();
              console.log(
                `[app.component#handleMessage] new params: ${newParams}`
              );
              const finalUrl = `${path}?${newParams}`;
              console.log(
                `[app.component#handleMessage] finalUrl: ${finalUrl}`
              );
              this.router.navigate([`/${tab}`], {
                queryParams: Object.fromEntries(searchParams.entries()),
              });
            }
          }
        } else {
          if (!isEmpty(currentTab)) {
            console.log(`[app.component#handleMessage] currentTab not empty`);
            const path: string = (currentTab as string).split('?')[0];
            const params: string = (currentTab as string).split('?')[1];
            console.log(`[app.component#handleMessage] path: ${path}`);
            console.log(`[app.component#handleMessage] params: ${params}`);
            const searchParams: URLSearchParams = new URLSearchParams(params);
            searchParams.set('projectId', projectId);
            const newParams: string = searchParams.toString();
            console.log(
              `[app.component#handleMessage] new params: ${newParams}`
            );
            const finalUrl = `${path}?${newParams}`;
            console.log(`[app.component#handleMessage] finalUrl: ${finalUrl}`);
            this.router.navigate([`/${tab}`], {
              queryParams: Object.fromEntries(searchParams.entries()),
            });
          }
        }
      }
    }
  }
  checkTabs(currentTab: string, tab: string) {
    if (currentTab != tab) {
      console.log(`current tab${currentTab} != new tab${tab}}`);
      console.log(`navigating user to new tab...`);
      this.router.navigate([`/${tab}`]);
    }
  }
  verifyOrigin(origin: string): boolean {
    return (
      /^https:\/\/(.*)\.vercel\.app$/gi.test(origin) ||
      /^https:\/\/(app|dev|staging|beta)\.getodin\.ai/gi.test(origin) ||
      /^http:\/\/localhost(:\d+)?(\/.*)?$/i.test(origin) ||
      /^https:\/\/(.*)\.github\.dev$/gi.test(origin) ||
      /^https:\/\/(.*)\.automationanywhere\.com$/gi.test(origin)
    );
  }
  ngOnDestroy() {
    this.authenticationService.logout();
  }
}

<ng-container *ngIf="!renderIframe">
  <div class="ap-typography-headline-5 ap-text-center ap-mb-4">Welcome Back</div>
  <div style="margin-bottom: 15px;">
    <ap-button
      [loading]="loading"
      btnColor="basic"
      btnStyle="stroked"
      btnSize="large"
      [fullWidthOfContainer]="true"
      (buttonClicked)="signInWithGoogle()"
    >
      <span
        style="display: flex; align-items: center; justify-content: center; flex-direction: row;"
      >
        <svg-icon
          style="margin-right: 8px;"
          class="ap-h-[28px] ap-w-[28px] ap-cursor-pointer"
          src="/assets/img/custom/auth/google-icon.svg"
          [applyClass]="true"
        ></svg-icon>
        <div>Sign in with Google</div>
      </span>
    </ap-button>
    <div class="ap-typography-headline ap-text-center ap-mt-2 ap-mb-2">OR</div>
  </div>
  <app-authentication-methods-separator></app-authentication-methods-separator>
  <form
    *ngIf="loginsWithEmailEnabled$ | async"
    [formGroup]="loginForm"
    (ngSubmit)="signIn()"
    class="ap-flex ap-flex-col ap-gap-2"
  >
    <mat-form-field class="ap-w-full" appearance="outline">
      <mat-label>Email</mat-label>
      <input
        type="email"
        matInput
        placeholder="Email"
        i18n-placeholder
        formControlName="email"
      />
      <mat-error *ngIf="loginForm.controls.email!.invalid">
        <ng-container
          *ngIf="loginForm.controls.email!.getError('email'); else emailRequired"
          i18n
        >
          Email is invalid
        </ng-container>
        <ng-template #emailRequired i18n>Email is required</ng-template>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="ap-w-full" appearance="outline">
      <mat-label i18n>Password</mat-label>
      <input
        type="password"
        matInput
        placeholder="Password"
        i18n-placeholder
        formControlName="password"
      />
      <mat-error *ngIf="loginForm.controls.password!.invalid" i18n
        >Password is required</mat-error
      >
    </mat-form-field>
    <mat-error
      *ngIf="showInvalidEmailOrPasswordMessage"
      class="ap-text-center"
      i18n
      >Invalid email or password</mat-error
    >
    <mat-error class="ap-text-center" *ngIf="noProjectsFound" i18n>
      You must have at least one project in order to login.
    </mat-error>
    <mat-error class="ap-text-center" *ngIf="invitationOnlySignIn" i18n>
      You are not invited to any project, please contact your administrator.
    </mat-error>
    <mat-error class="ap-text-center" *ngIf="showDisabledUser" i18n>
      Your user is inactive, please contact your administrator to activate it.
    </mat-error>
    <mat-error class="ap-text-center" *ngIf="domainIsNotAllowed" i18n>
      Your email domain is not allowed to sign up, please contact your
      administrator.
    </mat-error>
    <div
      *ngIf="showResendVerification"
      class="ap-text-center ap-flex-col ap-flex ap-items-center ap-justify-center ap-gap-2"
    >
      Please check your inbox to verify your account.
      <ap-button
        type="button"
        btnSize="small"
        btnStyle="stroked"
        (buttonClicked)="sendVerificationEmail()"
        [loading]="sendingVerificationEmail"
        [darkLoadingSpinner]="true"
        >Resend verification
      </ap-button>
    </div>

    <div class="text-center ap-mt-2 ap-flex ap-flex-col ap-gap-2">
      <ap-button
        [loading]="loading"
        (buttonClicked)="signIn()"
        [customColor]="true"
        btnColor="primary"
        btnSize="large"
        [fullWidthOfContainer]="true"
        i18n
      >
        Sign in
      </ap-button>
    </div>
  </form>
  <div class="ap-text-center ap-typography-caption ap-mt-4">
    <p>
      By logging in, you agree to our
      <a href="https://getodin.ai/terms-of-service" target="_blank" rel="noopener"
        >Terms of Service</a
      >
      and
      <a href="https://getodin.ai/privacy-policy" target="_blank" rel="noopener">
        Privacy Policy</a
      >.
    </p>
  </div>
  <!-- <div class="ap-typography-caption ap-text-body ap-text-center ap-mt-2">
  Don't have an account? <a routerLink="/sign-up">Get yours now</a>
</div> -->
  <ng-container *ngIf="authenticate$ | async"></ng-container>
  <ng-container *ngIf="sendVerificationEmail$ | async"></ng-container>
</ng-container>
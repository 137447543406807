/* eslint-disable @typescript-eslint/no-empty-function */
try {
  document.domain = 'getodin.ai';
} catch (err) {
  console.error("Error when setting document.domain to 'getodin.ai': ", err);
}

import { enableProdMode, isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment, isProd } from '@activepieces/ui/common';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/shell/shell';
import 'codemirror/addon/display/autorefresh';
import 'prismjs';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-sass';
import 'prismjs/components/prism-scss';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-cpp';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-json';

console.log('Prod', environment.production);

if (environment.production) {
  window.console.log = function () {};
  window.console.error = function () {};
  // window.console.warn = function () {};
  window.console.time = function () {};
  window.console.timeEnd = function () {};

  console.log = function () {};
  console.error = function () {};
  // console.warn = function () {};
  console.time = function () {};
  console.timeEnd = function () {};

  enableProdMode();
  Sentry.init({
    dsn: 'https://b3ffdb54df6851872b2731e85691b3a1@o4506342392791040.ingest.sentry.io/4506654972182528',
    environment: isDevMode()
      ? 'DEVELOPMENT'
      : isProd(window.location.origin)
      ? 'PRODUCTION'
      : 'STAGING',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

// trigger

<div class="ap-inline-block" *ngIf="url">
    <div [style.padding]="paddingSize"
        class="ap-rounded-full ap-bg-white ap-border ap-border-solid ap-border-dividers ap-flex ap-items-center ap-justify-center">
        <img [src]="url" [style.height]="iconSize + 'px'" [style.width]="iconSize + 'px'" class="ap-object-contain">
    </div>
</div>

<div class="ap-inline-block ap-relative">
    <div *ngIf="moreIconsNumber > 0"
        [style.padding]="paddingSize"
        class="ap-rounded-full ap-bg-white ap-border ap-border-solid ap-border-dividers  ap-flex ap-items-center ap-justify-center ap-ml-[5px]">
        <img  [style.height]="iconSize + 'px'" [style.width]="iconSize + 'px'" class="ap-object-contain ap-invisible ">
        <div class="ap-absolute" [style.fontSize]="moreNumberFontSize">
           <b> +{{moreIconsNumber}}</b>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FlowTemplate, ListFlowTemplatesRequest } from '@activepieces/shared';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { CreateFlowTemplateRequest } from '@activepieces/ee-shared';
import { OdinService } from './odin.service';
// import { FlagService } from './flag.service';

@Injectable({
  providedIn: 'root',
})
export class TemplatesService {
  constructor(private http: HttpClient) {} //, private flagsService: FlagService

  list(params: ListFlowTemplatesRequest): Observable<any> {
    let httpParams = new HttpParams();
    if (params.pieces && params.pieces.length > 0) {
      console.log(
        '[templates.service.ts#getTemplates] applying pieces filters...'
      );
      httpParams = httpParams.appendAll({ pieces: params.pieces });
    }
    if (params.tags && params.tags.length > 0) {
      console.log(
        '[templates.service.ts#getTemplates] applying tags filters...'
      );
      httpParams = httpParams.appendAll({ tags: params.tags });
    }
    if (params.search) {
      console.log(
        '[templates.service.ts#getTemplates] applying search filter...'
      );
      httpParams = httpParams.append('search', params.search);
    }
    if (OdinService.shouldWhiteLabel()) {
      httpParams = httpParams.append(
        'parentWindowURL',
        OdinService.getParentWindowURL()
      );
    }
    httpParams.append('limit', '1000');
    const url = environment.apiUrl + '/odin/flow-template';
    // return this.flagsService.getEdition().pipe(
    //   switchMap((ed) => {
    //     if (ed === ApEdition.COMMUNITY) {
    //       // url = 'https://cloud.activepieces.com/api/v1/flow-templates';
    //     }
    //     return this.http
    //       .get<SeekPage<FlowTemplate>>(url, {
    //         params: httpParams,
    //       })
    //       .pipe(map((res) => res.data));
    //   })
    // );
    return this.http.get<FlowTemplate>(url, {
      params: httpParams,
    });
  }

  create(request: CreateFlowTemplateRequest): Observable<FlowTemplate> {
    return this.http.post<FlowTemplate>(
      environment.apiUrl + '/flow-templates',
      request
    );
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(environment.apiUrl + `/flow-templates/${id}`);
  }

  getTemplate(flowId: string) {
    if (OdinService.shouldWhiteLabel()) {
      return this.http.get<any>(
        environment.apiUrl +
          `/odin/flow-template/${flowId}?parentWindowURL=${OdinService.getParentWindowURL()}`
      );
    }
    return this.http.get<any>(
      environment.apiUrl + `/odin/flow-template/${flowId}`
    );
  }
  getOdinTemplate(templateId: string) {
    if (OdinService.shouldWhiteLabel()) {
      return this.http.get<any>(
        environment.apiUrl +
          `/odin/flow-template/${templateId}?parentWindowURL=${OdinService.getParentWindowURL()}`
      );
    }
    return this.http.get<any>(
      environment.apiUrl + `/odin/flow-template/${templateId}`
    );
  }
}

<ap-dialog-title-template>
    <ng-container *ngIf="!dialogData.connectionToUpdate; else editConnectionHeader">Connect AI Notetaker</ng-container>
    <ng-template #editConnectionHeader>Reconnect AI Notetaker</ng-template>
</ap-dialog-title-template>

<mat-dialog-content>
    <ap-markdown *ngIf="dialogData.pieceAuthProperty.description"
        [data]="dialogData.pieceAuthProperty.description"></ap-markdown>
    <form class="ap-pt-4 ap-flex ap-flex-col ap-gap-2 ap-max-w-[720px] ap-min-w-[420px]" [formGroup]="settingsForm"
        (keyup.enter)="submit()" (submit)="submit()">
        <mat-form-field class="ap-w-full" appearance="outline">
            <mat-label>Name</mat-label>
            <input cdkFocusInitial [matTooltip]="keyTooltip" formControlName="name" matInput type="text" />
            <mat-error *ngIf="settingsForm.controls['name'].invalid">
                <ng-container *ngIf="settingsForm.get('name')!.getError('required'); else patternErrorOrAlreadyUsed">
                    Name is required
                </ng-container>
                <ng-template #patternErrorOrAlreadyUsed>
                    <ng-container *ngIf="settingsForm.get('name')!.getError('pattern');">
                        Name can only contain letters, numbers and underscores
                    </ng-container>
                    <ng-container *ngIf="settingsForm.get('name')!.getError('nameUsed');">
                        Name is already used
                    </ng-container>
                </ng-template></mat-error>
        </mat-form-field>
        <mat-error *ngIf="settingsForm.getError('message')" class="ap-text-left">{{settingsForm.getError('message')}}</mat-error>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="ap-flex ap-gap-2.5">
        <ap-button btnColor="basic" mat-dialog-close btnSize="default">
            Cancel
        </ap-button>
        <ap-button cdkFocusInitial btnSize="default" (click)="submit()"  [customColor]="true" btnColor="primary" type="submit"
            [loading]="loading">
            Connect
        </ap-button>
    </div>
</mat-dialog-actions>
<ng-container *ngIf="upsert$|async"></ng-container>
<div class="md:ap-max-w-[600px]">
  <ap-dialog-title-template i18n>Delete {{data.entityName}}
  </ap-dialog-title-template>
</div>

<mat-dialog-content>
  <div class="md:ap-max-w-[600px]">
    <div class="ap-typography-body-1 ap-text-body ap-mb-4" [innerHTML]="data.note">
    </div>
    <div class="ap-typography-body-1 ap-text-body ap-mb-4" i18n>
      Type <b>DELETE</b> and press confirm to continue.
    </div>
    <form class="ap-flex ap-flex-col ap-gap-1" [formGroup]="confirmationForm" (submit)="submit()">
      <mat-form-field class="ap-w-full" appearance="outline">
        <input cdkFocusInitial formControlName="confirmation" matInput type="text" placeholder="DELETE"
          class="!ap-uppercase" />
        <mat-error *ngIf="confirmationForm.controls.confirmation.invalid" i18n>
          Please write the confirmation message correctly
        </mat-error>
      </mat-form-field>
    </form>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="ap-flex ap-gap-2.5">
    <ap-button btnColor="basic" mat-dialog-close btnSize="default" btnColor="basic" [disabled]="!!deleteOperation$"
      i18n>
      Cancel
    </ap-button>
    <ap-button btnSize="default" type="submit" (click)="submit();" [loading]="!!deleteOperation$" btnColor="warn" i18n>
      Confirm
    </ap-button>
  </div>
</mat-dialog-actions>

<ng-container *ngIf="deleteOperation$ | async"></ng-container>
import { SignInRequest, User } from '@activepieces/shared';
import { Observable, from, mergeMap, switchMap, lastValueFrom } from 'rxjs';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '../environments/environment';
import firebase from '@firebase/app-compat';
import { get, isEmpty } from 'lodash';

const generateStrongSQLSafePassword = (length: number): string => {
  const charset =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()-_=+[]{}|:,.<>?';

  let password = '';
  const charsetLength = charset.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charsetLength);
    password += charset.charAt(randomIndex);
  }

  return password;
};

export const isProd = (hostname: string): boolean => {
  console.log(`[odin.service.ts#isProd] hostname: ${hostname}`);
  const result: boolean = /^(https:\/\/)?automations\.getodin\.ai$/.test(
    hostname
  );
  console.log(`[odin.service.ts#isProd] isProd?: ${result}`);
  return result;
};

export const OdinService = {
  async signInWithToken(
    http: HttpClient,
    token: string,
    email: string,
    uid: string,
    projectId: string
  ) {
    const payload: any = {
      accessToken: token,
      user: {
        email,
        password: generateStrongSQLSafePassword(24),
        uid,
      },
    };
    if (!isEmpty(projectId)) {
      payload.user.projectId = projectId;
    }
    const response = await lastValueFrom(
      http.post<User>(environment.apiUrl + '/odin/auth/sign-in', payload, {
        observe: 'response',
      })
    );

    localStorage.setItem('access-token', token);

    return response;
  },
  signInWithGoogle(http: HttpClient, afAuth: AngularFireAuth) {
    return from(
      afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
    ).pipe(
      switchMap((value) =>
        from(value.user?.getIdToken() || '').pipe(
          mergeMap((token) => {
            const payload = {
              accessToken: token,
              user: {
                email: value.user!.email,
                password: value.user!.uid,
                uid: value.user!.uid,
              },
            };
            // console.log(`payload: ${JSON.stringify(payload, null, 2)}`);
            return http.post<User>(
              environment.apiUrl + '/odin/auth/sign-in',
              payload,
              {
                observe: 'response',
              }
            );
          })
        )
      )
    );
  },
  signInWithOdin(
    request: SignInRequest,
    http: HttpClient,
    afAuth: AngularFireAuth
  ): Observable<HttpResponse<User>> {
    return from(
      afAuth.signInWithEmailAndPassword(request.email, request.password)
    ).pipe(
      switchMap((userCreds) =>
        from(userCreds.user?.getIdToken() || '').pipe(
          mergeMap((token) => {
            const payload = {
              accessToken: token,
              user: {
                ...request,
                uid: userCreds.user?.uid,
              },
            };
            console.log(`payload: ${JSON.stringify(payload, null, 2)}`);
            return http.post<User>(
              environment.apiUrl + '/odin/auth/sign-in',
              payload,
              {
                observe: 'response',
              }
            );
          })
        )
      )
    );
  },
  getApiUrl(): string {
    const host: string = window.location.origin;
    return get(environment, isProd(host) ? 'odinProdApiUrl' : 'odinApiUrl');
  },
  getParentWindowURL(): string {
    let parentWindowURL = '';
    try {
      parentWindowURL = get(document, 'location.ancestorOrigins[0]', '');
      if (!isEmpty(parentWindowURL)) {
        parentWindowURL = new URL(parentWindowURL).hostname;
      }

      console.log(
        `[odin-service#getParentWindowURL] parentWindowURL: ${parentWindowURL}`
      );
    } catch (err) {
      console.error(`[odin-service#getParentWindowURL] ERROR: ${err}`);
    }

    return parentWindowURL;
  },
  shouldWhiteLabel(): boolean {
    const url: string = this.getParentWindowURL();
    return /(automation)[-]?(anywhere)/gi.test(url) && !url.includes('/');
  },
};

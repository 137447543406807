<form [formGroup]="errorHandlingOptionsForm">
  <div class="ap-flex ap-flex-col ap-gap-3">
    <div
      apTrackHover
      #slider="hoverTrackerDirective"
      class="ap-flex ap-flex-col"
      *ngIf="!hideContinueOnFailure"
    >
      <mat-slide-toggle
        [formControl]="errorHandlingOptionsForm.controls.continueOnFailure"
        color="primary"
        class="ap-flex-grow-1"
        i18n
        >Continue on Failure</mat-slide-toggle
      >
      <div class="ap-flex ap-gap-2 ap-justify-between ap-markdown">
        <div class="ap-typography-caption">
          <markdown i18n
            >Enable this option to skip this step and continue the flow normally
            if it fails.</markdown
          >
        </div>
      </div>
    </div>
    <div
      apTrackHover
      #slider="hoverTrackerDirective"
      class="ap-flex ap-flex-col ap-align-center"
      *ngIf="!hideRetryOnFailure"
    >
      <mat-slide-toggle
        [formControl]="errorHandlingOptionsForm.controls.retryOnFailure"
        color="primary"
        class="ap-flex-grow-1"
        i18n
        >Auto Retry on Failure</mat-slide-toggle
      >
      <div class="ap-flex ap-gap-2 ap-justify-between ap-markdown">
        <div class="ap-typography-caption">
          <markdown i18n
            >Automatically retry up to four attempts when failed.</markdown
          >
        </div>
      </div>
    </div>
  </div>
</form>

<ng-container *ngIf="valueChanges$ | async"></ng-container>
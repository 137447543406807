import { AuthenticationService } from '@activepieces/ui/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent {
  constructor(private authService: AuthenticationService) {
    this.authService.setLoadingValue(false);
  }
}

export const environment = {
  production: true,
  apiUrl: '/api/v1',
  jwtTokenName: 'token',
  redirectUrl: 'https://automations.getodin.ai/api/redirect',
  userPropertyNameInLocalStorage: 'currentUser',
  //You need to edit index.html manually
  websiteTitle: 'Automator',
  activateBeamer: true,
  showFeedbackButton: true,
  showDocsButton: true,
  showUserProfile: true,
  // BEGIN EE
  firebaseProd: {
    apiKey: 'AIzaSyBl64WQ6SgWzhoXSW-QKxriO5_YSBdSrf8',
    authDomain: 'odin-ai-production.firebaseapp.com',
    projectId: 'odin-ai-production',
    storageBucket: 'odin-ai-production.appspot.com',
    messagingSenderId: '181233548789',
    appId: '1:181233548789:web:a9d94e6ccd9b0fc19b74bc',
    measurementId: 'G-6GSV1GLL6W',
  },
  firebase: {
    apiKey: 'AIzaSyANb7euT8jR1MyXbTFcviaXy2wj47xeMqE',
    authDomain: 'ai-content-writer-e0839.firebaseapp.com',
    databaseURL: 'https://ai-content-writer-e0839-default-rtdb.firebaseio.com',
    projectId: 'ai-content-writer-e0839',
    storageBucket: 'ai-content-writer-e0839.appspot.com',
    messagingSenderId: '1069018982835',
    appId: '1:1069018982835:web:c2ed3b783dbdfd3b9483c6',
    measurementId: 'G-8XD1X9ELXM',
  },
  // END EE
  odinApiUrl: 'https://ecs-api.getodin.ai',
  odinProdApiUrl: 'https://ecs-prod.getodin.ai',
  odinPostHogKey: 'phc_Wd6Q9exKPO3byYeTuY9Sw0M2oJMRC7eMf6fNanYiJwb',
};

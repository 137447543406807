<ng-container *ngIf="!renderIframe">
  <div
    class="ap-h-screen ap-w-full ap-flex ap-items-center ap-justify-center ap-bg-center ap-bg-cover ap-bg-authBg ap-flex-col">
    <!-- <a href="https://www.activepieces.com/" rel="noopener" target="_blank">
      <img alt="Activepieces Website" src="assets/img/custom/logo/full-logo.svg" class="ap-mb-2">
    </a> -->
    <h1 class="logo">
      <img alt="Automator" src="assets/img/custom/logo/odin-logo.svg">
      <span>Automator</span>
    </h1>

    <mat-card class="md:ap-min-w-[480px] md:ap-max-w-initial ap-min-w-[350px] ap-max-w-[350px] auth-card">
      <router-outlet></router-outlet>
    </mat-card>
  </div>
</ng-container>
<div *ngIf="renderIframe"
  class="ap-flex ap-flex-grow ap-justify-center ap-items-center ap-h-full route-loader ap-w-full">
    <ng-lottie [options]="options" width="100px" class="ap-text-center"></ng-lottie>
    <router-outlet></router-outlet>
</div>
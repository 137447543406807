import { Component } from '@angular/core';
import {
  AuthenticationService,
  FlagService,
  OdinService,
} from '@activepieces/ui/common';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthLayoutComponent {
  fullLogoUrl$: Observable<string>;
  renderIframe = false;
  options = {
    path: OdinService.shouldWhiteLabel()
      ? '/assets/lottie/aa-loader.json'
      : '/assets/lottie/odin-loader.json',
  };
  constructor(
    private flagService: FlagService,
    private authService: AuthenticationService
  ) {
    this.renderIframe = window.self !== window.top;
    this.fullLogoUrl$ = this.flagService
      .getLogos()
      .pipe(map((logos) => logos.fullLogoUrl));
    if (!this.renderIframe) {
      this.authService.setLoadingValue(false);
    }
  }
}

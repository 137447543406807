<div (click)="builderAutocompleteService.revertDataInsertionPopupSize(); searchInput.focus();"
  class="ap-typography-subtitle-1 !ap-font-semibold ap-flex ap-items-center ap-px-5 ap-py-2 ap-cursor-pointer">Data to
  Insert<div class="ap-flex-grow">
  </div>
  <app-autocomplete-dropdown-sizes-buttons></app-autocomplete-dropdown-sizes-buttons>
</div>
<mat-form-field (click)="$event.stopPropagation();" appearance="fill"
  [class.cdk-visually-hidden]="(builderAutocompleteService.dataInsertionPopupSize$ | async) === 'collapse'"
  [class.!ap-border-none]="(builderAutocompleteService.dataInsertionPopupSize$ | async) === 'collapse'"
  class="ap-w-full ap-border-t ap-border-solid ap-border-outline data-insertion-search" subscriptSizing="dynamic">
  <mat-icon class="ap-ml-2.5" matPrefix svgIcon="search"></mat-icon>

  <input id="searchInput" #searchInput [formControl]="searchFormControl" matInput placeholder="Search"
    autocomplete="off">
  <ap-icon-button [class.!ap-visible]="searchFormControl.value" matSuffix class="ap-mr-[4.5rem] ap-invisible"
    (buttonClicked)="searchFormControl.setValue('')" [iconFilename]="'close.svg'"></ap-icon-button>
</mat-form-field>
<div
  class="ap-max-h-[338px] ap-h-[338px] ap-max-w-[357px] ap-w-[357px] ap-overflow-y-auto ap-overflow-x-hidden thin-scrollbars"
  [class.!ap-h-full]="(builderAutocompleteService.dataInsertionPopupSize$ | async) === 'fullscreen'"
  [class.!ap-w-full]="(builderAutocompleteService.dataInsertionPopupSize$ | async) === 'fullscreen'"
  [class.!ap-max-h-full]="(builderAutocompleteService.dataInsertionPopupSize$ | async) === 'fullscreen'"
  [class.!ap-max-w-full]="(builderAutocompleteService.dataInsertionPopupSize$ | async) === 'fullscreen'"
  [class.!ap-h-0]="(builderAutocompleteService.dataInsertionPopupSize$ | async) === 'collapse'">

  <ng-container *ngFor="let stepMention of stepsMentions$|async" #stepsListTemplate>
    <ng-container [ngSwitch]="stepMention.step.type">
      <ng-container
        *ngIf="stepMention.step.type === ActionType.CODE || stepMention.step.type === ActionType.LOOP_ON_ITEMS ||  stepMention.step.type === ActionType.PIECE">
        <app-action-mention-item [stepIndex]="stepMention.step.indexInDfsTraversal"
          (mentionClicked)="mentionClicked($event)" [stepMention]="stepMention"></app-action-mention-item>
      </ng-container>

      <ng-container *ngSwitchCase="TriggerType.PIECE">
        <app-piece-trigger-mention-item [stepIndex]="stepMention.step.indexInDfsTraversal"
          (mentionClicked)="mentionClicked($event)" [stepMention]="stepMention"></app-piece-trigger-mention-item>
      </ng-container>


    </ng-container>


  </ng-container>


</div>

<ng-container *ngIf="focusSearchInput$ | async"></ng-container>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AuthenticationService } from './authentication.service';
import { OdinService } from './odin.service';
import { Observable, map } from 'rxjs';
import { ProjectWithLimits, SeekPage, User } from '@activepieces/shared';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  setToken(request: any): Observable<HttpResponse<User>> {
    return this.http.post<User>(
      environment.apiUrl + '/odin/token/update',
      request,
      {
        observe: 'response',
      }
    );
  }
  async createOdinProjects(params: any): Promise<Observable<any>> {
    const token: string = await this.authService.getAccessToken();
    const headers = new HttpHeaders({
      'access-token': token,
    });
    const requestOptions = { headers: headers };
    const odinApiUrl: string = OdinService.getApiUrl();
    return this.http.post(
      odinApiUrl + '/project/create',
      params,
      requestOptions
    );
  }

  list(): Observable<ProjectWithLimits[]> {
    return this.http
      .get<SeekPage<ProjectWithLimits>>(environment.apiUrl + `/users/projects`)
      .pipe(map((res) => res.data));
  }
}

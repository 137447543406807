import { Component } from '@angular/core';

import { isProd as checkIsProd } from '@activepieces/ui/common';

@Component({
  selector: 'app-iframe-validate',
  templateUrl: './iframe-validate.component.html',
  styleUrls: ['./iframe-validate.component.scss'],
})
export class IframeValidateComponent {
  isProd: boolean = checkIsProd(window.location.origin);
}

<div
class="ap-h-screen ap-w-full ap-flex ap-items-center ap-justify-center ap-bg-center ap-bg-cover ap-bg-authBg ap-flex-col"> 
<h1 class="logo">
  <img alt="Automator" src="assets/img/custom/logo/odin-logo.svg">
  <span>AI Automator</span>
</h1>

<mat-card class="md:ap-min-w-[30rem] md:ap-max-w-initial ap-min-w-[21.875rem] ap-max-w-[21.875rem] auth-card">
    <div class="ap-typography-headline-5 ap-text-center ap-mb-4">AI Automator can only be accessed through our <a [href]="isProd ? 'https://app.getodin.ai' : 'https://staging.getodin.ai'">main application</a>.</div>
</mat-card>
</div>